import { Atom, Cmp } from ":mods";
import { Ctrl } from ":mods";
import { TopCourse } from "./navs";

export const LOCALSTORAGE_KEYS = {
  student_selected_stage: "STUDENT_SELECTED_STAGE",
};

// Stages Number
export const STAGES = {
  1: "Foundation Online Course",
  2: "Online Advance",
  3: "Online In-Person",
  4: "In-Person Exchange",
};

export enum ENDPOINTS {
  // get_course_list = "/admin/course/list",
  get_stages_list = "/course/list/",
  stage_listdetails_with_stage_id = "/course/stage/listdetails/",
  module_list_with_stage_id = "/admin/course/module/list/",
  module_details_with_module_id = "/admin/course/module/details/",
  submodule_list_with_module_id = "/admin/course/submodule/list/",
  submodule_details_with_submodule_id = "/admin/course/submodule/details/",
  material_list_with_submodule_id = "/admin/course/material/list/",
  material_details_with_material_id = "/admin/course/material/details/",
  material_details_with_material_id_student = "/course/material/details/",
  submission_list = "admin/submission/list/:stageId/:statusId/",
  submission_detials = "admin/submission/details/:stageId/:applicationID/",
  submission_student = "admin/student/list/:applicationID/", // :applicationID/?task_id=107 || :applicationID/?question_id=107
}

export const MATERIAL_BUILDER_ITEM_TYPES = {
  image: null as string,
  video: null as string,
  text: null as string,
};

export const MATERIAL_TYPES = {
  lesson: 1,
  question: 2,
  task: 3,
};

const STAGES_LIST = [
  {
    id: 1,
    open: true,
    caption: "Museum Futures | Courses ",
    title: "Foundation Course",
    link: "foundation/schedule",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
  },
  {
    id: 2,
    open: false,
    caption: "Museum Futures | Courses ",
    title: "Advance Course",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
  },
  {
    id: 3,
    open: false,
    caption: "Museum Futures | Courses ",
    title: "Exchange Programme",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
  },
];

export const STUDENT_COURSES = {
  count: 3,
  completed: 0,
  running: 1,
  stages: STAGES_LIST,
};
const LESSON_DATA = [
  {
    id: 1,
    name: "Description",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    id: 2,
    name: "Discussion",
    description:
      "2 Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est sit ab officiis fuga iusto repellendus magnam aliquam fugiat velit id, pariatur tenetur quia officia. Voluptatum deserunt placeat reprehenderit velit dolorum.",
  },
  {
    id: 3,
    name: "Notes",
    description:
      "3 Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est sit ab officiis fuga iusto repellendus magnam aliquam fugiat velit id, pariatur tenetur quia officia. Voluptatum deserunt placeat reprehenderit velit dolorum.",
  },
];
export const MOD_DATA = {
  submodules: [
    {
      id: 6,
      name: "History of Museums",
      module: 2,
      lessons: [
        {
          id: "lsn_1",
          name: "Video content name",
          icon: "video",
          content: LESSON_DATA,
        },
        {
          name: "Name of the reading content  ",
          length: 15,
          icon: "reading",
        },
        {
          name: "Quiz",
          length: 15,
          icon: "quiz",
        },
      ],
    },
    {
      id: 7,
      name: "Museums as Organisations",
      module: 2,
      lessons: [
        {
          name: "Lesson four",
          length: 1,
          icon: "video",
        },
      ],
    },
  ],
};

export const SUBMISSION_STATUSES = {
  pending: "200",
  approved_for_Advance: "201",
  approved_for_in_person: "202",
  shortlisted: "205",
  rejected: "400",
};

export const ROUTES = {
  side: {
    courses: {
      foundation: (id?: number | string) => `/courses/foundation/${id ?? ":id"}`,
      foundation_module: (id?: number | string) => `/courses/foundation/module/${id ?? ":id"}`,
      foundation_submodule: (id?: number | string) => `/courses/foundation/submodule/${id ?? ":id"}`,
      foundation_material: (id?: number | string) => `/courses/foundation/material/${id ?? ":id"}`,
      foundation_submissions_list: (id?: string, statusId?: string) =>
        `/courses/foundation/submissions/${id ?? ":id"}/${statusId ?? ":statusID"}`,
      foundation_submission_student: (id?: string, statusId?: string, applicationID?: string) =>
        `/courses/foundation/submissions/${id ?? ":id"}/${statusId ?? ":statusID"}/${
          applicationID ?? ":applicationID"
        }`,
      // advanced and advanced stage are the same route, preparing it to be splitted for later
      advanced: (id?: number | string) => `/courses/advanced/${id ?? ":id"}`,
      advanced_module: (id?: number | string) => `/courses/advanced/module/${id ?? ":id"}`,
      advanced_submodule: (id?: number | string) => `/courses/advanced/submodule/${id ?? ":id"}`,
      advanced_material: (id?: number | string) => `/courses/advanced/material/${id ?? ":id"}`,
    },
  },
  student: {
    all_courses: "/",
    foundation: "/foundation/courses",
    question: "/foundation/courses/question/:id",
    show_task: "/foundation/courses/task/:id",
    edit_task: "/foundation/courses/task/:id/edit",
    edit_team: "/foundation/courses/team/:id/edit",
  },
  tutor: {
    all_courses: "/",
  },
};
export const ANCHORES = {
  // top: {
  //   course: ((p) => TopCourse(p)) as Ctrl.Nav.model.TopNavJSXItem,
  // },
  side: {
    admin: {
      foundation: {
        course_material: {
          title: "Edit Course Material",
          to: ROUTES.side.courses.foundation(1),
          icon: {
            idle: "icon-local-nav-side:course-material-idle",
            active: "icon-local-nav-side:course-material-active",
          },
        },

        submissions: [
          {
            title: "Foundation Submissions",
            to: ROUTES.side.courses.foundation_submissions_list("1", SUBMISSION_STATUSES.pending),
            icon: {
              idle: "icon-local-nav-side:submissions-idle",
              active: "icon-local-nav-side:submissions-active",
            },
          },
          {
            title: "Shortlisted for Advance Course",
            to: ROUTES.side.courses.foundation_submissions_list("1", SUBMISSION_STATUSES.shortlisted),
            icon: {
              idle: "icon-local-nav-side:resources-idle",
              active: "icon-local-nav-side:resources-active",
            },
          },
          {
            title: "Approved",
            to: ROUTES.side.courses.foundation_submissions_list("1", SUBMISSION_STATUSES.approved_for_in_person),
            icon: {
              idle: "icon-local-nav-side:approved-idle",
              active: "icon-local-nav-side:approved-active",
            },
          },
          {
            title: "Not Approved",
            to: ROUTES.side.courses.foundation_submissions_list("1", SUBMISSION_STATUSES.approved_for_Advance),
            icon: {
              idle: "icon-local-nav-side:not-approved-idle",
              active: "icon-local-nav-side:not-approved-active",
            },
          },
        ] as Atom.Route.model.AnchorItemGroup,
      },
      advanced: [
        {
          title: "Edit Course Material",
          to: ROUTES.side.courses.advanced(2),
          icon: {
            idle: "icon-local-nav-side:course-material-idle",
            active: "icon-local-nav-side:course-material-active",
          },
        },
      ] as Atom.Route.model.AnchorItemGroup,
    },
  },
};
